$primary: #023779 !default;
$theme-contrast-color: #fff !default;
$theme1: #4DBAB5;
$theme2: #FFCE30;
$primary-light: #CBE4FA;

$border-width: 1px;
$theme-colors: (
        'theme1': $theme1,
        'theme2': $theme2,
        'primary-light': $primary-light
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 900;
$enable-rounded: true;
$enable-shadows: false;
$link-decoration: underline;
$nav-link-padding-x: .5rem;
$border-radius: 13px;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$headings-color: $primary;


@import "~daterangepicker";

// import font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,600;1,700&display=swap');

$font-family-base: 'Poppins', sans-serif;

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';

#main-navigation {
  .dropdown-menu {
    top: 115% !important;
    border-top: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    .dropdown-submenu {
      border: none;
    }
    .dropdown-item:not(.sub-dropdown .dropdown-item) {
      @include media-breakpoint-up(lg) {
        font-weight: bold;
      }
    }
  }
}

.dropdown-submenu {
  .sub-dropdown {
    a.nav-link {
      background: #f9fcff;
      &:hover {
        background: #eaf4ff;
      }
    }
  }
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
}

.navbar-brand--img {
  width: 70px;
}

.dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}

.nav-item {
  @extend .list-unstyled;
}

#main-navigation {
  display: inline-flex;
  background-color: $primary-light;
  border-radius: $border-radius;
  font-weight: bold;
  @extend .p-2, .mt-2;
  .dropdown-menu {
    padding: .5rem 0;
  }
}

.navigation-socials {
  a {
    padding: .2rem 0;
    font-size: 1.4rem;
  }
}

.navbars {
  text-align: end;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  .follow-us {
    @extend .d-none;
  }

  .top-bar--item {
    @extend .list-unstyled;
    .top-bar--link {
      @extend .text-dark, .text-decoration-none, .nav-link;
    }
    &:first-child {
      .top-bar--link {
        @extend .text-theme1;
      }
    }
  }
}

.direct-search {
  @extend .ml-2;
  .input-group-text {
    background: none;
  }
  .btn--label {
    display: none;
  }
}

.thin {
  font-weight: 200;
}

.img-cover {
  height: 478px;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-up(md) {
    background-position: left;
  }
}

.bottom-flow {
  bottom: -2px;
}

.hero-flavor-extra--img {
  position: absolute;
  bottom: -60px;
  right: 2rem;
  height: auto;
  width: 200px;
  @include media-breakpoint-down(sm) {
    bottom: -55px;
    width: 125px;
  }
}

.animated-link {
  i {
    @extend .ml-1;
    transition: transform .2s;
  }
  &:hover {
    i {
      transform: translateX(6px);
    }
  }
}

.content-search {
  @extend .my-5;
  .content-search--form {
    margin: 0;
  }
}

.hero-flavor-01--img {
  max-height: 480px;
  bottom: -2px;
  position: relative;
}

.home-content-flavor {
  position: absolute;
  width: 172px;
  right: 3rem;
  bottom: 0;
  @include media-breakpoint-down(md) {
    position: relative;
    width: 75px;
  }
}

.badge-wrapper {
  width: 60px;
  right: 0rem;
  @extend .mr-4
}

.img-wrapper {
  .date-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: solid 9px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @extend .p-2, .mr-4, .bg-white;
  }
}

.text-theme2 {
  .date-badge--string {
    color: $dark;
  }
}

.agenda-split--right {
  @extend .pl-lg-4, .col-lg-6;
}

.agenda-split--left {
  @extend .col-md-6;
  .agenda-split--line-break {
    @extend .mt-4;
  }
}

.agenda-split--left, .agenda-item, .news-item {
  .card-title {
    @extend .mb-1;
  }
  .card-body {
    @extend .pt-3;
  }
  .card {
    @extend .bg-transparent, .mb-4;
  }
}

.card-stretch-hover {
  .card--img-top {
    width: 100%;
    transition: transform .3s;
  }
  &:hover {
    .card--img-top {
      transform: scale(1.1);
    }
  }
}

.footer-menu {
  .bold, .footer-menu--item {
    font-weight: inherit !important; // reset
    a {
      text-decoration: none;
      color: $white;
      display: block;
      @extend .mb-2;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-contact {
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-socials {
  a {
    color: $white;
    font-size: 1.4rem;
  }
}

.footer-wave {
  width: 100%;
  position: relative;
  bottom: -1px;
}

.footer-flavor-1 {
  position: absolute;
  bottom: -75px;
  left: -75px;
  width: 200px;
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    width: 150px;
    left: -50px;
    bottom: -50px;
  }
}

.footer-flavor-backdrop {
  width: 80vw;
  position: absolute;
  opacity: .5;
  bottom: 65%;
  left: -10%;
  z-index: -9;
  max-width: 1500px;
  @include media-breakpoint-up(md) {
    width: 60vw;
    bottom: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 60vw;
    bottom: 0;
  }
}

.footer-flavor-2 {
  position: absolute;
  bottom: 35px;
  right: calc(-300px - -30vw);
  width: 85px;
  max-width: 263px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.footer-flavor-3 {
  position: absolute;
  bottom: 35px;
  right: 0;
  width: 100px;
}

.video-banner--body {
  @extend .bg-theme2, .border-0, .text-primary;
}

.mobile-menu {
  display: contents;
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
  .nav-link {
    color: $dark !important;
  }
}

.breadcrumb--bar {
  background-color: $theme2;
  a {
    text-decoration: none;
  }
}

.part-category-nav {
  a {
    text-decoration: none;
  }
}

.part--card {
  .card-img-top {
    @extend .rounded;
  }
  @extend .border-0;
  .card-body {
    @extend .py-3
  }
  .card-title {
    a {
      @extend .text-decoration-none;
    }
  }
}

.accordion button::after {
  color: $primary;
}

.wide-banner, .content-banner {
  img {
    transition: transform .3s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

body > .wrapper {
  margin-bottom: -85px;
}

.account-button--style {
  @extend .btn-primary;
}

.has-user-content {
  table {
    tr th:first-child {
      border-top-left-radius: 10px;
    }
    tr th:last-child {
      border-top-right-radius: 10px;
    }
    th {
      border: transparent;
    }
    td {
      border-color: #dadada !important;
    }
  }

}

.form-group {
  .text-muted {
    font-size: 0.7em;
  }
}
